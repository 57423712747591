import componentFactory from '@dovetail/core/core';
const exportableComponents = {
    LaunchPadStandalone: () => import(
    /* webpackChunkName: "LaunchPadStandalone" */ './app/LaunchPadStandaloneScreen'),
    LaunchPad: () => import(
    /* webpackChunkName: "LaunchPadEmbeddedScreen" */ './app/LaunchPadEmbeddedScreen'),
    AdminScreen: () => import(/* webpackChunkName: "AdminScreen" */ './admin/AdminScreen'),
};
export default class LaunchPad {
    config;
    components;
    constructor(config) {
        this.config = config;
        this.config = config;
        this.components = Object.keys(exportableComponents).reduce((accumulator, componentName) => ({
            ...accumulator,
            [componentName]: componentFactory(exportableComponents[componentName], this.config),
        }), {});
    }
}
