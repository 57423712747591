const validateProps = (parameters, parametersDefinition) => {
    Object.keys(parametersDefinition).forEach((key) => {
        const value = parameters[key];
        const valueType = typeof value;
        const expectedType = parametersDefinition[key];
        // checking against `undefined` here because we want to support Falsy parameters like `false`
        if (value === undefined || valueType !== expectedType) {
            throw Error(`An invalid type was provided for the prop ${key}. Expected: ${expectedType}, received: ${valueType}.`);
        }
    });
};
const validateEnum = (parameters, parametersDefinition) => {
    Object.keys(parameters).forEach((key) => {
        if (!Object.values(parametersDefinition[key]).includes(parameters[key])) {
            throw Error(`An invalid type was provided for the prop ${key}. Expected: ${Object.values(parametersDefinition[key]).toString()}`);
        }
    });
};
export { validateProps, validateEnum };
